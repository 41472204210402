import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

Bugsnag.start({
  apiKey: '5b704496651c9293d8808162f695b938',
  plugins: [new BugsnagPluginVue()],
  enabledReleaseStages: ['production'],
  logger: null,
});

export default Bugsnag.getPlugin('vue');
